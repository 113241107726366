/*****************************************************************

                     CAWI AUTOFILLER 3000
                    auto-form-filling-tool
                   ULTIMATE CHOPPER EDITION

           .=======================ooooooo
   ___   ,'    \_________________________________________
 /   /-/   dP  /                           ////////////  ''--..._
 \___\-\  dP   \                           \\\\\\\\\\\\  __..--'
        `---------------------------------''''''''''''''

*******************************************************************/

const cawiAutoFiller3000 = $component => {
    // PROPERTIES
    var $survey;
    var run = false;
    var formList;
    var enableScreenout = false;
    
    // UTILITIES
    var keyboardShortcut = {
        leftAlt:false,
        space:false,
        keydown: function (e) {
            if (e.keyCode === 17) {
                this.leftAlt = true;
            }
            if (e.keyCode === 32) {
                this.space = true;
            }
        },
        keyup: function (e) {
            if (e.keyCode === 17) {
                this.leftAlt = false;
            }
            if (e.keyCode === 32) {
                this.space = false;
            }
        },
        isPressed: function () {
            return this.leftAlt && this.space;
        }

    };
    function fireCustomEvent(eventName, element, data) {
        'use strict';
        // https://stackoverflow.com/questions/23705136/is-it-possible-to-trigger-drag-events-programmatically
        var event;
        data = data || {};
        if (document.createEvent) {
            event = document.createEvent("HTMLEvents");
            event.initEvent(eventName, true, true);
        } else {
            event = document.createEventObject();
            event.eventType = eventName;
        }

        event.eventName = eventName;
        event = $.extend(event, data);

        if (document.createEvent) {
            element.dispatchEvent(event);
        } else {
            element.fireEvent("on" + event.eventType, event);
        }
    }
    function randomElFromArray(array) {
        if (array.length === undefined || array.length === 0) {
            return null;
        } else {
            return array[Math.floor(Math.random()*array.length)];
        }
    }

    // INITIALIZATION and ENDIFICATION
    var initFill = function () {
        if (!$survey) {
            $survey = $('#se2-content');
        }
        if (!formList) {
            formList = [];
            $.each(survey.getQuestionaryInfo().formList, function (i, form) {
                formList.push(form.id);
            });
        }
        enableScreenout = $component.find('[data-cawiautofiller3000-screenout]').is(':checked') ;
        run = true;
        $component.addClass('running');
    };

    var endFill = function () {
        run = false;
        $component.removeClass('running');
    };

    // SOME DATA
    var clever_facts = [
        'Rovníková Guinea neleží přesně na rovníku.',
        'Ruský lékař Leonid Rogozov si v podmínkách antarktické základny sám vyoperoval slepé střevo?',
        '84 % žen má rádo muže v růžovém tričku. Paradoxně 87 % mužů v růžovém tričku nemá rádo ženy.',
        'Ernest Vincent Wright napsal román Gadsby, který obsahuje přes 50.000 slov, ale ani jedno písmeno "e".',
        'Když napíšete do Googlu číslo 241543903 vyhledá vám spoustu lidí s hlavou v lednici.',
        'Hawaii se přibližuje Japonsku rychlostí 4 palce ročně.'
    ];
    var fruits = ['Jablko','Hruška','Třešně','Višně','Švestka','Bluma','Slíva','Mirabelka','Trnka','Meruňka','Broskev','Jeřabiny','Oskeruše','Mandle','Lískový ořech','Vlašský ořech','Kaštan jedlý','Borůvka','Brusinka','Černý rybíz','Rybíz červený','Bílý rybíz','Angrešt','Hrozny','Malina','Ostružina','Jahoda','Ananas','Citron','Grapefruit','Mandarinka','Pomelo','Pomeranč','Banán','Datle','Durian','Fík','Granátové jablko','Kaki','Karambola','Kiwi','Kdoule','Liči','Mango','Naši','Papája','Avokádo','Oliva','Kokosový ořech', 'Ty jsi kokos'];
    var numbers = [7,69,1337,80085];

    // FILLERS
    var fillSlider = function ($form) {
        $.each($form.find('.noUi-base'), function (i, el) {
            fireCustomEvent('mousedown', el);
        });
    };

    var fillCheckbox = function ($form) {
        $.each($form.find('input[type="checkbox"]'), function (i, el) {
            if (Math.random() > 0.5 ) {
                fireCustomEvent('click', el);
            }
        });
    };

    var fillTextarea = function ($form) {
        $.each($form.find('textarea'), function (i, el) {
            $(el).val( randomElFromArray(clever_facts) );
            fireCustomEvent('keyup', el);
        });
    };

    var fillRadio = function ($form) {
        var radio = randomElFromArray( $form.find('input[type="radio"]') );
        if (radio) {
            fireCustomEvent('click', radio);
        }
    };

    var fillRadioTable = function ($form) {
        $.each($form.find('table.pure-form tr'), function (i, tr) {
            var $tr = $(tr);
            if ($tr.find('input[type="radio"]').length > 1) {
                fillRadio( $(tr) );
            }
        });
    };

    var fillInputText = function ($form) {
        $.each($form.find('input[type=text]'), function (i, el) {
            var $el = $(el);
            if (!$el.val() || $el.closest('.invalid').length) {
                $el.val( $.isNumeric($el.val()) ? randomElFromArray(fruits) : randomElFromArray(numbers) );
            }
            fireCustomEvent('keyup', el);
        });
    };

    // ULTIMATE FILLER 3000 FORM FILL MOTOFOKO HANDLER
    var fill = function () {
        var $grid = $survey.find('.grid');

        fillCheckbox($grid);

        // wait moment for checkboxes reaction
        setTimeout(function () {
            fillRadio($grid);
            fillSlider($grid);
            fillTextarea($grid);
            fillInputText($grid);
            fillRadioTable($grid);
        }, 10);
    };

    // SURVEY CONTROL
    var nextForm = function() {
        if (enableScreenout) {
            // simulate click on next button
            var $nextBtn = $survey.find('button.next');
            if ($nextBtn.length) {
                fireCustomEvent('click', $nextBtn[0]);
            }
        } else {
            // hack survey and bypass next button
            var currentForm = survey.getCurrentForm();
            var nextFormIndex = formList.indexOf(currentForm) + 1;

            if (nextFormIndex >= formList.length) {
                // is last
                endFill();
            } else {
                survey.showForm(formList[nextFormIndex]);
            }
        }
    };

    // HANDLERS
    var fillHandler = function(e) {
        e.preventDefault();
        initFill();

        var tryCount = 0;

        // Try to fill max 10 times
        var fillLoop = function () {
            fill();
            tryCount++;

            if (!survey.questionary.currentForm.validate() && tryCount < 10) {
                // Fail. Try again! Never give up!
                setTimeout(fillLoop, 25);
            } else {
                nextForm();
                endFill()
            }
        };
        fillLoop();
    };

    var runHandler = function (e) {
        e.preventDefault();

        if (run) {
            // stop if running
            endFill();
            return;
        } else {
            initFill();
        }

        var formLoop = function () {
            if (!run) {
                return;
            }

            var tryCount = 0;

            // Try to fill max 10 times
            var fillLoop = function () {
                if (!run) {
                    return;
                }

                fill();
                console.log(tryCount);
                tryCount++;

                if (!survey.questionary.currentForm.validate() && tryCount < 10) {
                    // Fail. Try again! Never give up!
                    setTimeout(fillLoop, 25);
                } else if (tryCount >= 10) {
                    // give up
                    endFill();
                } else {
                    nextForm();
                    // And continue in filling
                    setTimeout(formLoop, 100);
                }
            };
            fillLoop();
        };
        formLoop();
    };

    var keyboardKeydownHandler = function(e) {
        if (e.keyCode === 27) {
            endFill();
        }

        keyboardShortcut.keydown(e);
        if (keyboardShortcut.isPressed() && !run) {
            initFill();

            var tryCount = 0;

            // Try to fill max 10 times
            var fillLoop = function () {
                fill();
                tryCount++;

                if (survey.questionary.currentForm && !survey.questionary.currentForm.validate() && tryCount < 10) {
                    // Fail. Try again! Never give up!
                    setTimeout(fillLoop, 25);
                } else {
                    nextForm();
                    endFill()
                }
            };
            fillLoop();
        }
    };

    var keyboardKeyupHandler = function(e) {
        keyboardShortcut.keyup(e);
    };

    // ATTACH EVENTS <=> HANDLERS
    $component.find('[data-cawiautofiller3000-fill]').on('click', fillHandler);
    $component.find('[data-cawiautofiller3000-run]').on('click', runHandler);
    $(document).on('keydown', keyboardKeydownHandler);
    $(document).on('keyup', keyboardKeyupHandler);
};

export default cawiAutoFiller3000;
