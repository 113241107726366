import BaseComponent from "../../BaseComponent";

export default class QuestionaryGoToForm extends BaseComponent {
	init(): void {
		this.$component.on('click', e => {
			e.preventDefault();

			// @ts-ignore
			window?.survey.questionary.goTo(this.$component.data('target'));
		});
	}
}
