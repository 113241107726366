// Main script file of questionary
import naja, {Naja} from 'naja';
import LiveForm from './extensions/LiveForm';
import { Nette } from 'live-form-validation';

declare global {
	interface Window {
		naja: Naja,
        Nette: { init: () => void, validateForm: (sender, onlyCheck) => boolean },
        LiveForm: LiveForm,
	}
}

window.naja = naja; // because @fingerprint.latte
window.Nette = Nette;
window.LiveForm = LiveForm;

import ExtensionRegistrar from './extensions/ExtensionRegistrar';
import {ComponentFactory, ComponentsType} from "./components/ComponentFactory";
import QuestionaryComment from "./components/QuestionaryComment";
import QuestionaryGoToForm from "./components/QuestionaryGoToForm";
import QuestionaryAutoFiller from "./components/QuestionaryAutoFiller";
import QuestionaryProblem from "./components/QuestionaryProblem";

// Components
const components: ComponentsType = {};
components.QuestionaryComment = QuestionaryComment;
components.QuestionaryGoToForm = QuestionaryGoToForm;
components.QuestionaryAutoFiller = QuestionaryAutoFiller;
components.QuestionaryProblem = QuestionaryProblem;

// Extensions
ExtensionRegistrar.addExtension(new ComponentFactory(components));

ExtensionRegistrar.register();

document.addEventListener('DOMContentLoaded', () => {
	naja.initialize();
});
