import BaseComponent from "../../BaseComponent";
import html2canvas from "html2canvas";

export default class QuestionaryComment extends BaseComponent {

	init() {
		this.$component.on('keydown keyup', e => e.stopPropagation());
		if (this.$screenshotInput.length) {
			this.toggleSubmit(false);
			this.addScreenshot();
		}
		if (this.$formIdInput.length) {
			// @ts-ignore
			this.$formIdInput.val(window.survey?.getCurrentForm());
		}
	}

	private get $submitInput(): JQuery {
		return this.$component.find('input[type="submit"]');
	}
	private get $screenshotInput(): JQuery {
		return this.$component.find('input[name="screenshot"]');
	}
	private get $formIdInput(): JQuery {
		return this.$component.find('input[name="formId"]');
	}

	private toggleSubmit(enable: boolean): void {
		this.$submitInput.css('opacity', enable ? '1' : '0.6')
			.prop('enabled', enable ? 'enabled' : 'disabled');
	}

	private addScreenshot(): void {
		html2canvas(document.body).then( canvas => {
			this.$screenshotInput.val(canvas.toDataURL());
			this.addScreenshotFinally();
		}, ( /*error*/) => {
			this.addScreenshotFinally();
		})
	}

	private addScreenshotFinally(): void {
		this.toggleSubmit(true);
		this.removeFlashMessage();
	}

	private removeFlashMessage() {
		setInterval( () => {
			$.each(this.$component.find('[data-remove-after-seconds]:not(.commit-suicide)'), function (index, el) {
				const $el = $(el);
				$el.addClass('commit-suicide');
				const timeout = $el.data('remove-after-seconds') * 1000;
				setTimeout(function () {
					$el.remove();
				}, timeout);
			});
		},1000);
	}

}
