export default class QuestionaryProblemModal {
	constructor(private readonly id: string)
	{ }

	private get modalEl(): HTMLElement {
		return document.getElementById(this.id)
	}

	public show(): void {
		this.modalEl.style.display = 'flex';
	};

	public hide(): void {
		this.modalEl.style.display = 'none';
	};

	public toggle(): void {
		if (this.modalEl.style.display === 'none') {
			this.show();
		} else {
			this.hide();
		}
	}

};