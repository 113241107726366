import BaseComponent from "../../BaseComponent";
const superagent = require("./superagent.js"); // TODO nahradit za naja?
import html2canvas from "html2canvas";
import QuestionaryProblemModal from "./QuestionaryProblemModal";

export default class QuestionaryProblem extends BaseComponent {
	private url = "";
	private text = "";
	private modal?: QuestionaryProblemModal;

	init(): void {
		this.modal = new QuestionaryProblemModal('questionaryProblem-modal');
		this.url = this.$component.data('url');

		this.$component.find('#questionaryProblem-toggle').on('click', () => {
			this.modal.toggle();
			this.$component.find('#textarea').focus();
		});
		this.$component.find('[data-close-modal]').on('click', () => this.modal.toggle());
		this.$component.find('input[name="send"]').on('click', () => this.sendQuestionaryProblem());
	}

	private sendQuestionaryProblem(): void {
		this.text = String(this.$component.find('#textarea').val());
		this.send();
		this.modal.hide();
		this.$component.find('#textarea').val('');
	}

	private getData(onFinishData): void {
		const data: any = {};
		let detail = "\n***********************\nDetailed informations\n\n";
		try {
			let formId = "FormId:\tUnknown form, Survey not loaded\"\n";
			// @ts-ignore
			if (window.survey !== undefined && window.survey instanceof Survey) {
				// @ts-ignore
				formId = "FormId:\t" + survey.getCurrentForm() + "\n";
			}
			detail += formId;

			if (navigator !== undefined && navigator instanceof Navigator) {
				const properties = [
					'appCodeName', 'appName', 'appVersion',
					'cookieEnabled', 'language',
					'onLine', 'platform', 'product', 'userAgent'
				];

				for (let i in properties) {
					detail += "Navigator." + properties[i] + ":\t" + navigator[properties[i]] + "\n";
				}

				if ("connection" in navigator) {
					try {
						// @ts-ignore
						detail += "Navigator.NetworkInformation.downlink:\t" + navigator.connection.downlink + "\n";
						// @ts-ignore
						detail += "Navigator.NetworkInformation.effectiveType:\t" + navigator.connection.effectiveType + "\n";
					} catch(error) { }
				}
			}
		} catch(error) {
			detail += "JavaScript error:\t" + error.message + "";
		}


		data.text = this.text + "\n" + detail;

		html2canvas(document.body, {allowTaint: true, useCORS: true}).then( function (canvas) {
			try {
                data.screenshot = canvas.toDataURL();
            } catch (err) {
                // well, continue without screenshot
            }
			onFinishData(data);
		}, function ( /*error*/ ) {
			// continue without screenshot
			onFinishData(data);
		});
	};

	private send(): void {
		const request = superagent;
		this.getData(data => {
			request
				.post(this.url)
				.send(data)
				.set('Accept', 'application/json')
				.end( function(err, res){
					console.log(err, res);
				});
		});
	};

}